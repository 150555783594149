<template>
	<div class="RightsManagement">
		<div class="RightsManagement-T">
			<div class="RightsManagement-TL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称"></inputs>
					</div>
					<div class="RightsManagement-tit-mai1-btn">
						<el-button type="danger" icon="el-icon-search" @click="inputValue">查询</el-button>
					</div>
				</div>
			</div>
			<div class="RightsManagement-TR">
				<div class="RightsManagement-searchsMai">
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
					<el-button type="primary" v-permission="'Power:Add'" @click="clickNew()" icon="el-icon-plus">新增权限</el-button>
				</div>
			</div>
		</div>
		<div class="RightsManagement-B">
			<div class="RightsManagement2">
				<el-table :data="tableData" border style="width: 100%" size="small">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column prop="T_name" label="权限名称" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column label="修改时间" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							{{scope.row.UpdateTime}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="操作" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<el-button size="small" v-permission="'Power:Edit'" @click="allpir('a', scope.row)">编辑</el-button>
							<el-button size="small" v-permission="'Power:Del'" @click="allpir('b', scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="RightsManagement-pagination">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog :title="titles"  :visible.sync="dialogVisible" :append-to-body="true">
			<div class="NewUser">
				<div style="max-height: 500px;overflow-y: auto;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
						<el-form-item label="权限名称" prop="T_name">
							<el-input v-model="ruleForm.T_name" placeholder="请输入名称"></el-input>
						</el-form-item>
						<el-form-item label="菜单权限">
							<el-tree
							  :data="dataTree"
							  show-checkbox
							  ref="tree"
							  node-key="Id"
							  :default-checked-keys="checkedkeys"
							  accordion
							  :props="defaultProps"
							  @check-change="nodeCick"
							  >
							</el-tree>
						</el-form-item>
						<el-form-item label="权限选择">
							<el-checkbox-group v-model="ruleForm.T_select">
								<el-checkbox v-for="item1 in dataPowerAll" :label="item1.Id" :key="item1.Id">{{item1.T_name}}</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="报警权限">
							<el-checkbox-group v-model="ruleForm.T_warning">
								<el-checkbox v-for="item1 in dataWarning" :label="item1.Id" :key="item1.Id">{{item1.T_name}}</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-form>
				</div>
				<div style="border-top: 1px solid #ccc;padding-top: 10px;">
					<el-form label-width="80px" class="demo-ruleForm">
						<el-form-item>
							<el-button type="danger" @click="submitForm('ruleForm')">立即提交</el-button>
							<el-button @click="dialogVisible=false">取消</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		PowerList,
		PowerEdit,
		PowerAdd,
		PowerDel,
		MenuAll,
		PowerAll,
		WarningTypeAll,
		PowerGet
	} from '../../api/RightsManagement.js'
	export default {
		data() {
			return {
				checkedkeys:[],
				dataPowerAll:[],//权限多选数据
				dataWarning:[],//报警多选数据
				dataTree: [],
				defaultProps: {
				  children: 'Children',
				  label: 'T_name'
				},
				dataShow:true,
				police:true,
				titles:'',
				total:0,
				pages:{
					T_name:'',
					page:1,
					page_z:10,
				},
				ruleForm: {
					T_name: '',
					T_select: [],//权限选择
					T_warning:[],//报警权限
					T_menu: [],//选择的权限树
				},
				rules: {
					T_name: [{
						required: true,
						message: '请输入权限名称',
						trigger: 'blur'
					}],
				},
				dialogVisible: false,
				Page4: 5, //当前页
				loading: false,
				formInline: {
					user: '',
					region: ''
				},
				tableData:[],
			}
		},
		mounted() {
			this.GetPowerListApi()
		},
		methods: {
			nodeCick(e){
				////console.log(e,this.$refs.tree.getCheckedKeys())
			},
			GetPowerListApi(){
				PowerList(this.pages).then(res=>{
					if(res.data.Code===200){
						this.tableData = res.data.Data.Data
						this.total = res.data.Data.Num
					}
					
				})
			},
			inputValue(e){
				////console.log('搜索',e)
				this.pages.T_name = e
				this.pages.page = 1
				this.GetPowerListApi()
			},
			submitForm(formName) {
				var that = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.titles === '新增权限'){
							var item = {
								T_name: this.ruleForm.T_name,
								T_select: 'S'+this.ruleForm.T_select.join("|S")+'|',//权限选择
								T_warning:'W'+this.ruleForm.T_warning.join("|W")+'|',//报警权限
								T_menu: 'M'+this.$refs.tree.getCheckedKeys().join("|M")+'|',//选择的权限树
							}
							PowerAdd(item).then(res=>{
								if(res.data.Code===200){
									this.$message.success('恭喜你，新增权限成功')
									this.pages.page = 1
									this.GetPowerListApi()
									setTimeout(function() {
										that.dialogVisible = false
									}, 1000)
								}
							})
						}else{
							var item = {
								T_id:this.ruleForm.T_id,
								T_name: this.ruleForm.T_name,
								T_select: 'S'+this.ruleForm.T_select.join("|S")+'|',//权限选择
								T_warning:'W'+this.ruleForm.T_warning.join("|W")+'|',//报警权限
								T_menu: 'M'+this.$refs.tree.getCheckedKeys().join("|M")+'|',//选择的权限树
							}
							PowerEdit(item).then(res=>{
								if(res.data.Code===200){
									this.$message.success('恭喜你，权限编辑成功')
									this.pages.page = 1
									this.GetPowerListApi()
									setTimeout(function() {
										that.dialogVisible = false
									}, 1000)
								}
							})
						}
					} else {
						////console.log('error submit!!');
						return false;
					}
				});
			},
			cx() {
				var _this = this
				this.loading = true
				setTimeout(function() {
					_this.loading = false
				}, 1000)
			},
			clickNew() {
				if(this.$refs.ruleForm){//为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
				////console.log('新增权限',this.ruleForm)
				////console.log('*****',this.ruleForm)
				this.getMenuAll()
				this.getPowerAll()
				this.getWarningTypeAll()
				Object.keys(this.ruleForm).forEach(key=>{
					if(key=='T_name'){
						this.ruleForm[key] = ''
					}else{
						this.ruleForm[key] = []
					}
				})
				this.dialogVisible = true
				this.titles = "新增权限"
			},
			
			getMenuAll(){
				MenuAll({}).then(res=>{
					////console.log('权限树',res)
					if(res.data.Code===200){
						this.dataTree = res.data.Data.Data
					}
				})
			},
			getPowerAll(){
				var that = this
				PowerAll({}).then(res=>{
					if(res.data.Code===200){
						that.dataPowerAll = res.data.Data.Data
					}
					////console.log('权限选择',res,this.dataPowerAll)
				})
			},
			getWarningTypeAll(){
				var that = this
				WarningTypeAll({}).then(res=>{
					if(res.data.Code===200){
						that.dataWarning = res.data.Data
					}
					////console.log('报警权限选择',res,this.dataWarning)
				})
			},
			handleSizeChange(e) { //每页多少条
				////console.log('每页多少条',e)
				this.pages.page_z = e
				this.GetPowerListApi()
			},
			handleCurrentChange(e) { //跳转页
				////console.log('跳转页',e)
				this.pages.page = e
				this.GetPowerListApi()
			},
			getPowerGet(item){
				return new Promise((resolve, reject) => {
					PowerGet({T_id:item.Id}).then(res=>{
						res.data.Code===200?resolve(res.data.Data):''
					})
				})
			},
			async allpir(i, item) {
				if (i === "a") { //编辑
					if(this.$refs.ruleForm){//为了验证第一次触发表单验证，第二次打开还是触发
						this.$refs.ruleForm.resetFields();
					}
					var data = await this.getPowerGet(item)
					this.checkedkeys = data.Menu_checked
					this.dataPowerAll = data.Select
					this.dataWarning = data.Warning
					this.ruleForm.T_name = data.Power.T_name
					this.ruleForm.T_id = data.Power.Id
				
					this.ruleForm.T_warning = []
					this.ruleForm.T_select = []
					data.Warning.forEach(item=>{
						item.T_check?this.ruleForm.T_warning.push(item.Id):''
					})
					data.Select.forEach(item=>{
						item.T_check?this.ruleForm.T_select.push(item.Id):''
					})
					this.dataTree = data.Menu
					this.titles = "编辑权限"
					this.dialogVisible = true
				} else { //删除
					this.$confirm('此操作将执行删除, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						center: true
					}).then(() => {
						PowerDel({T_id:item.Id}).then(res=>{
							if(res.data.Code===200){
								this.$message.success('操作成功，权限已经删除')
								this.pages.page = 1
								this.GetPowerListApi()
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			onSubmit() {
				////console.log('submit!');
			}
		}
	}
</script>

<style lang="scss">
	.el-dropdown-menu--mini .el-dropdown-menu__item {
		line-height: 35px;
		padding: 0 10px;
		font-size: 12px;
	}
	.RightsManagement {
		user-select: none;
		.RightsManagement-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.RightsManagement-TL {
				display: flex;
				align-items: center;

				.RightsManagement-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.RightsManagement-tit-mai1-btn {
						
					}

					.RightsManagement-searchs1 {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.RightsManagement-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}
		
		.RightsManagement-B{
			background: rgba(#fff, 1);
			margin: 10px;
		}
		.RightsManagement2 {
			// border-radius: 10px;
			overflow: hidden;
			margin: 10px;
			padding: 20px;
		}

		.RightsManagement-pagination {
			// background: rgba(#31353e,1);
			padding:50px 20px;
			border-radius: 2px;
			// .el-pagination.is-background .btn-next,
			// .btn-prev,
			// .el-pagination.is-background .el-pager li {
			// 	//未选中
			// 	margin: 0 5px;
			// 	background-color: #222;
			// 	color: #fff;
			// 	min-width: 30px;
			// 	border-radius: 2px;
			// }

			// .el-pagination.is-background .el-pager li:not(.disabled).active {
			// 	//选中
			// 	background-color: #fff;
			// 	color: #222;
			// }

			// .el-pagination__total {
			// 	//总total
			// 	margin-right: 10px;
			// 	font-weight: 400;
			// 	// color: #fff;
			// }

			// .el-pagination__jump {
			// 	//跳转页
			// 	margin-left: 24px;
			// 	font-weight: 400;
			// 	// color: #fff;
			// }
		}
	}
</style>
